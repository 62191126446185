// extracted by mini-css-extract-plugin
export var badge = "TuitionNew-module--badge--abe2c";
export var bg = "TuitionNew-module--bg--6a1ab";
export var block = "TuitionNew-module--block--28dfe";
export var content = "TuitionNew-module--content--ac3a0";
export var description = "TuitionNew-module--description--1d26f";
export var dropdown = "TuitionNew-module--dropdown--b7e2b";
export var graph = "TuitionNew-module--graph--19b21";
export var heading = "TuitionNew-module--heading--69c17";
export var item = "TuitionNew-module--item--17d25";
export var label = "TuitionNew-module--label--4350b";
export var level = "TuitionNew-module--level--4a929";
export var line = "TuitionNew-module--line--1cdd6";
export var linesLeft = "TuitionNew-module--linesLeft--ee710";
export var linesRight = "TuitionNew-module--linesRight--e35e7";
export var point = "TuitionNew-module--point--c7389";
export var price = "TuitionNew-module--price--c7454";
export var salary = "TuitionNew-module--salary--f75d5";
export var salaryItems = "TuitionNew-module--salaryItems--ca383";
export var subtitle = "TuitionNew-module--subtitle--1cc82";
export var title = "TuitionNew-module--title--1a4fe";
export var tuition = "TuitionNew-module--tuition--efc5a";
export var value = "TuitionNew-module--value--95e8b";